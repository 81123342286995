import { useState } from 'react';

export const useLocalStorage = (storageKey: string, initialValue?: unknown) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(storageKey);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: unknown) => {
    try {
      // enables matching useState type signature
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== "undefined") {
        window.localStorage.setItem(storageKey, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const clearValue = () => window.localStorage.removeItem(storageKey);

  return [storedValue, setValue, clearValue];
}
