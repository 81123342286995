import React from 'react';
import { SortIndicator } from 'components';
import { SortOrder, SortBy } from 'generated';
import { Analytics } from 'components/util/AnalyticEvents';
import { SortByOptions } from 'business-logic';
import { Obj } from 'utility';
import { TableWrapper } from './styled.Table';

interface TableProps {
  columns: TableColumn[];
  rows?: Obj[];
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  setSortBy?: (columnValue: SortBy) => void;
  setSortOrder?: (order: SortOrder) => void;
  sortByCategory?: string;
  rowHeight?: string;
  className?: string;
  sx?: React.CSSProperties | undefined;
}

interface TableColumn {
  label: string;
  value: string;
  title?: string;
  onClick?: (col_value: string) => void;
  disableSort?: boolean;
}

export function Table(props: TableProps) {
  const {
    columns,
    rows,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    sortByCategory,
    rowHeight,
    className,
    sx,
  } = props;

  Analytics.Interaction({
    field: 'Sort',
    value: (() => `${sortBy === 'CATEGORY_SCORE' ? sortByCategory : sortBy} - ${sortOrder}`)(),
  });

  return (
    <TableWrapper className={className} rowHeight={rowHeight} style={sx}>
      <table className="test-table">
        <thead className="table-header-row">
          <tr>
            {columns.map((col, index) => {
              const headerValue = col.value.toUpperCase();
              const columnActive =
                headerValue === sortBy ||
                (sortBy === 'CATEGORY_SCORE' && headerValue === sortByCategory?.toUpperCase());

              return (
                <th
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                  title={col.title ?? col.label}
                  onClick={
                    col.onClick
                      ? () => {
                        if (col.onClick) col.onClick(col.value);
                      }
                      : () => {
                        if (!col.disableSort && setSortBy && setSortOrder) {
                          // Only run sort function if header column value is a valid SortBy enum
                          if (
                            Object.entries(SortByOptions).some(
                              // eslint-disable-next-line
                              ([_key, value]) => value === headerValue
                            )
                          ) {
                            if (columnActive) {
                              setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
                            } else {
                              setSortBy(headerValue as SortBy);
                            }
                          }
                        }
                      }
                  }
                  key={`th-${col.value}-${index}`}
                >
                  <div className="header-content">
                    <span>{col.label}</span>
                    {sortBy && !col.disableSort && (
                      <SortIndicator active={columnActive} sortOrder={sortOrder} />
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows &&
            rows.map((dat, index) => (
              <tr key={`tr-${index}`}>
                {columns.map((col, innerIndex) => (
                  <td key={`td-${col.value}-${innerIndex}`}>
                    {typeof dat[col.value] !== 'function' ? dat[col.value] : dat[col.value]()}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </TableWrapper>
  );
}
