import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'sterling-redux/store';
import {
  ActiveSelectionsAction,
  ActiveSelectionsState,
} from 'sterling-redux/reducers/activeSelections';
import { IParams, getUrlLanguage } from 'utility';

export interface DeepLinkParam {
  name: keyof ActiveSelectionsState;
  reducer: (x: string) => ActiveSelectionsAction;
}

interface DeepLinkProps {
  boundParams: DeepLinkParam[];
  baseUrlEnding: string;
}
//!!!!!!! READ THIS !!!!!!!
/**
 * If you're adding deep linking to a route, don't forget
 * to add REST params to the route in src/routing/Routes.tsx
 */
export const useDeepLink = (props: DeepLinkProps) => {
  const { boundParams, baseUrlEnding } = props;
  const queryParams = useParams<IParams>();
  const dispatch = useDispatch();
  const location = useLocation();
  const boundParamState = useSelector((state: AppState) => {
    const newState = {} as any;
    boundParams.forEach((param: DeepLinkParam) => {
      newState[param.name] = state.activeSelections[param.name];
    });
    return newState;
  });

  const basePath = location.pathname.split(baseUrlEnding).slice(0, -1).join('/');
  const fallbackUrl = `${basePath}${baseUrlEnding}`;

  const [stateNeedsUpdate, setStateNeedsUpdate] = useState(
    Object.keys(queryParams).every(
      (key) => boundParams.find((p) => p.name === key) === (queryParams as Partial<typeof boundParamState>)[key]
    )
  );
  const [urlNeedsUpdate, setUrlNeedsUpdate] = useState(true);

  const generateUrlFromBoundParams = () =>
    boundParams
      .slice(0)
      .reduce(
        (url: string, param: DeepLinkParam) => url + `/${boundParamState[param.name]}`,
        ''
      );

  const handleQueryParamUpdate = () => {
    // Identify the base URL without the baseUrlEnding or any parameters that will be updated.
    const baseUrl = location.pathname.includes(baseUrlEnding)
      ? location.pathname.split(baseUrlEnding)[0]
      : location.pathname.split('/').slice(0, -1).join('/');

    const updatedUrl = `${baseUrl}${baseUrlEnding}${generateUrlFromBoundParams()}`;

    window.history.replaceState('', '', updatedUrl);
  };

  const handleBoundParamStateUpdate = () => {
    if (
      Object.keys(queryParams).every(
        (key) => boundParams.find((p) => p.name === key) === (queryParams as Partial<typeof boundParamState>)[key]
      )
    ) {
      boundParams.forEach((param: DeepLinkParam) => {
        if (
          queryParams[param.name] &&
          queryParams[param.name] !== '' &&
          queryParams[param.name] !== undefined
        ) {
          dispatch(param.reducer(queryParams[param.name] || ''));
        }
      });
      setStateNeedsUpdate(false);
    }
    setUrlNeedsUpdate(true);
  };

  const clearParamStoreState = () => {
    boundParams.forEach((param: DeepLinkParam) => {
      dispatch(param.reducer(''));
    });
  };

  function DeepLinkFallback(fallbackProps: { errorOverride?: { en: string; fr: string } }) {
    const { errorOverride } = fallbackProps;
    if (Object.keys(queryParams).length) {
      setUrlNeedsUpdate(false);
      clearParamStoreState();
      window.location.href = fallbackUrl;
    }
    return (
      <h1
        style={{
          textAlign: 'center',
          marginTop: '36px',
        }}
      >
        {
          {
            EN: errorOverride?.en || 'No dealers registered to this account.',
            FR: errorOverride?.fr || `Aucun concessionnaire n'est enregistré sur ce compte.`,
          }[getUrlLanguage(location.pathname)]
        }
      </h1>
    );
  }

  useEffect(() => {
    if (Object.keys(queryParams).length) {
      boundParams.forEach((param: DeepLinkParam) => {
        dispatch(param.reducer(queryParams[param.name] || ''));
      });
    }
  }, []);

  useEffect(() => {
    if (stateNeedsUpdate) handleBoundParamStateUpdate();
    if (urlNeedsUpdate) handleQueryParamUpdate();
  });

  return Object.assign(boundParamState, {
    DeepLinkFallback: (fallbackProps: { errorOverride?: { en: string; fr: string } }) => (
      <DeepLinkFallback errorOverride={fallbackProps.errorOverride} />
    ),
  });
};
export default useDeepLink;
