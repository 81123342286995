/* eslint-disable @typescript-eslint/no-explicit-any */
import { LabelValue, OEM } from "./types";

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item: unknown) {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function deepMerge<T>(target: any, ...sources: any): T {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    // eslint-disable-next-line
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepMerge(target, ...sources);
}

export const BREAKPOINTS = ["xs", "sm", "md", "lg", "xl"] as const;

export const handleEnterKey = (
  e: React.KeyboardEvent,
  cb: () => void
): void => {
  if (e.key === "Enter") {
    return cb();
  }
};

/**
 * Converts an array of label/value objects into a single object with values as keys and boolean as values.
 * Intended to be used to track the state of a Checkbox form
 */
export const reduceToBooleanMap = (
  options: LabelValue[]
): Record<string, boolean> => {
  return options.reduce(
    // eslint-disable-next-line -- [TODO] [CLARITY] Remove janky comma and make more explicit what is happening
    (m, v) => ((m[v.value] = false), m),
    {} as Record<string, boolean>
  );
};

export const oemNameFromShortCode = (code: OEM): string => {
  switch (code) {
    case "HACC":
      return "Hyundai";
    case "MMSC":
      return "Mitsubishi";
  }
  return "Demo";
};

export const oemShortCode = (oemName = "demo"): OEM =>
  /(hyundai|hacc|hac)/gi.test(oemName)
    ? "HACC"
    : /(mitsubishi|mmsc|mmscan)/gi.test(oemName)
    ? "MMSC"
    : "DEMO";

export const oemShortCodeFromUrl = (): OEM =>
  oemShortCode(window.location.href);

export const getStandardDeviation = (array: number[]) => {
  const n = array.length;
  if (n === 0) return 0;
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    // eslint-disable-next-line
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  );
};
export type FlexDirection = "row" | "column";

export const oppositeDirection = (d: FlexDirection): FlexDirection =>
  d === "row" ? "column" : "row";
