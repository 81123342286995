/* eslint-disable */
import React, { useEffect } from 'react';
import { getToken, getUrlLanguage } from '../../utility';
import { useLocation } from 'react-router-dom';
import { oemShortCodeFromUrl } from '../../utility';
import * as amplitude from '@amplitude/analytics-browser';

const globalWindow = window as any;

type InteractionValue = string | number | boolean | '';

interface InteractionArgs {
  field: any;
  value?: InteractionValue;
}

export interface AnalyticsConfig {
  field?: string;
  value?: InteractionValue;
}

/**
 * Custom Dimension Index
 */

const DIMS = {
  UserRole: 'user_role',
  Oem: 'oem',
  Language: 'language',
  ContentGroup: 'content_group',
  LoginType: 'login_type',
};

const updateAmplitude = (args: Record<string, unknown>) => {
  const AMP = globalWindow.amplitude;

  if (typeof AMP !== 'object') return;
  if (args?.event) {
    const { event, ...rest } = args;
    amplitude.track({
      event_type: event as string,
      ...rest,
    });
    return;
  }

  const keyValPairs = Object.entries(args);
  const identifyEvent = new amplitude.Identify();
  keyValPairs.forEach((item) => {
    if (['user_role', 'oem', 'login_type'].includes(item[0])) {
      identifyEvent.setOnce(item[0], item[1] as string);
    } else {
      identifyEvent.set(item[0], item[1] as string);
    }
    AMP.identify(identifyEvent);
  });
};

const googleDataLayerPush = (args: Record<string, unknown>) =>
  globalWindow.dataLayer.push(args);
const getContentGroup = () => globalWindow.localStorage.getItem('ga4_content_group') || '';

//? This is the method that will be used for tracking events
const updateTracking = updateAmplitude;

/**
 * Configuration functions
 */

const getDims = () => {
  const token = getToken();
  const properties = {
    ...(token && { [DIMS.UserRole]: token.coe_role }),
    ...(token && { [DIMS.LoginType]: token.login_type }),
    ...(token && { [DIMS.Language]: getUrlLanguage(window.location.pathname) }),
    [DIMS.Oem]: oemShortCodeFromUrl(),
    [DIMS.ContentGroup]: getContentGroup(),
  };
  return properties;
};

const updateParams = () => {
  const token = getToken();
  if (!token) return;
  updateTracking(getDims());
};

const usePageViews = () => {
  const location = useLocation();
  const token = getToken();
  useEffect(() => {
    updateTracking({
      event: 'page_view',
      page_location: `${window.location.hostname + location.pathname}`,
      page_title: `${window.document.title}`,
      user_agent: `${window.navigator.userAgent}`,
      client_id: `${token?.username}`,
    });
  }, [location]);
};

const useInteraction = (
  { field, value }: InteractionArgs,
  isFirstLoad: React.MutableRefObject<boolean>
) => {
  useEffect(() => {
    isFirstLoad.current
      ? (isFirstLoad.current = false)
      : value !== ''
        ? updateTracking({
            event: 'selection',
            category: 'user_interaction',
            action: `${field} Changed`,
            label: `${field} => ${value}`,
          })
        : (() => {})();
  }, [value, isFirstLoad]);
};

const useClick = (field: string | JSX.Element) => {
  updateTracking({
    event: 'click',
    category: 'user_interaction',
    action: `${field} Clicked`,
    label: `${field} => Click`,
  });
};

const useDownload = (field: string | JSX.Element, url: string) => {
  updateTracking({
    event: 'download',
    category: 'user_interaction',
    action: `${field} Clicked`,
    label: `File: ${url}`,
  });
};

const useListFilter = (field: string | JSX.Element, value: string) => {
  updateTracking({
    event: 'filter',
    category: 'user_interaction',
    action: `${field} Used`,
    label: `${value}`,
  });
};

const useLinkClicked = (field: string | JSX.Element, url: string) => {
  updateTracking({
    event: 'link_click',
    category: 'user_interaction',
    action: `${field} Clicked`,
    label: `Link: ${url}`,
  });
};

const useModalOpen = (modalProps: Record<string, unknown>) => {
  updateTracking({
    event: 'modal_view',
    ...modalProps,
  });
};

const useLoadTime = (
  firstLoad: React.MutableRefObject<boolean>,
  time: React.MutableRefObject<number>,
  label: string
) => {
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    } else {
      updateTracking({
        event: 'timing_complete',
        category: 'Query',
        label: `${label}`,
        duration: `${performance.now() - time.current}`,
      });
    }
  });
};

const googleInit = () => {
  if (typeof globalWindow.gtag !== 'function') {
    globalWindow.dataLayer = globalWindow.dataLayer || [];
    globalWindow.gtag = function () {
      globalWindow.dataLayer.push(arguments);
    };
    globalWindow.gtag('js', new Date());
  }
};

const amplitudeInit = () => {
  const token = getToken();

  if (typeof globalWindow.amplitude !== 'function') {
    if (process.env.REACT_APP_AMPLITUDE_KEY) {
      const ampInstance = amplitude.createInstance();

      if (token) {
        ampInstance.init(process.env.REACT_APP_AMPLITUDE_KEY, token.username, {
          identityStorage: 'localStorage',
        });
      } else {
        ampInstance.init(process.env.REACT_APP_AMPLITUDE_KEY, {
          identityStorage: 'localStorage',
        });
      }
      globalWindow.amplitude = ampInstance;
    }
  }
}

export const Analytics = (() => {
  const _this = {
    init: () => {
      // googleInit();
      amplitudeInit();
      updateParams();
      return _this;
    },
    Config: {
      User: updateParams,
    },
    Pageview() {
      usePageViews();
      return <></>;
    },
    ContentGroup(contentGroup: string) {
      globalWindow.localStorage.setItem('ga4_content_group', contentGroup);
      updateParams();
    },
    Interaction(args: InteractionArgs, config?: AnalyticsConfig) {
      useInteraction(Object.assign(args, config), React.useRef(true));
    },
    ButtonClick(event: React.MouseEvent, name: string | JSX.Element, callback: any) {
      useClick(name);
      return callback && callback(event);
    },
    Download(field: string | JSX.Element, url: string) {
      useDownload(field, url);
    },
    LinkClick(field: string | JSX.Element, url: string) {
      useLinkClicked(field, url);
    },
    ModalOpen(modalProps: Record<string, unknown>) {
      useModalOpen(modalProps);
    },
    ListFilter(field: string | JSX.Element, value: string) {
      useListFilter(field, value);
    },
    LoadTime(
      label: string | undefined,
      firstLoad: React.MutableRefObject<boolean>,
      initialTime: React.MutableRefObject<number>
    ) {
      if (!label) return;
      if (label?.match(/(apiRoutesQuery)|(sterlingQuery)/g)) return;
      if (label?.match(/(Query)/g)) {
        useLoadTime(firstLoad, initialTime, `${label}`);
      }
    },
    Hookless: {
      SearchRedirect(keyword: string, path: string) {
        updateTracking({
          event: 'search',
          category: 'Content Search',
          action: `Keyword => ${keyword}`,
          label: `Selected => ${path}`,
        });
      },
    },
  };

  return _this.init();
})();
