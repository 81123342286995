import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { DropdownBaseStyle } from '../styled.Dropdown.Base';

export const useDropdownBaseStyles = (small?: boolean, buttonLook?: boolean) => {
  const dropdownBaseStyle = DropdownBaseStyle(small, buttonLook);
  return makeStyles(() =>
    createStyles({
      ...dropdownBaseStyle,
      popupIndicator: {
        width: '25px',
      },
      adornments: {
        top: 'calc(50% - 16px)',
      },
    })
  );
};

export const DropdownWrapperDiv = styled.div`
  select {
    border-radius: 0;
  }

  fieldset {
    border: none !important;
  }
`;

export const InteriorInputWrapperDiv = styled.div<{ noIcon?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  > svg:first-child {
    position: absolute;
    left: 0;
  }

  .MuiFormControl-root {
    .MuiInputBase-root {
      background: transparent !important;
      padding-left: ${(props) => (props.noIcon ? '10px' : '45px')};
      cursor: pointer !important;

      .MuiInputBase-input {
        height: auto !important;
        line-height: 1em;
      }

      .MuiInputBase-input::placeholder {
        opacity: 1;
      }
    }

    .MuiAutocomplete-endAdornment {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      .MuiAutocomplete-popupIndicator {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
`;
