import React from 'react';
import { BarChart, Bar, XAxis, YAxis, LabelList, ResponsiveContainer } from 'recharts';
import { percentageDisplay } from 'utility';
import { OemTheme } from 'assets/styles/themes/functions';
import { colours } from 'assets/styles/themes/resources';
import { useSelector } from 'react-redux';
import { AppState } from 'sterling-redux/store';

export interface ScoreBarData {
  description: string;
  value: number;
  compValue: number;
  link?: string;
}

export const barColours = () => ({
  primary: `${OemTheme.colour.graph.bar.primary}`,
  secondary: `${OemTheme.colour.graph.bar.secondary}`,
  background: `${OemTheme.colour.graph.track}`,
});

const renderCustomizedLabel = (props: unknown) => {
  const typeProps = props as React.SVGProps<SVGTextElement> & ScoreBarData;
  const { x, y, width, value } = typeProps;

  return (
    <text x={(x as number) + (width as number) + 5} y={y} dy={12} className="bar-percentage">
      {percentageDisplay(value)}
    </text>
  );
};

export function ScoreBar(props: { data: ScoreBarData[] }) {
  const { data } = props;

  const printMode = useSelector((state: AppState) => state.activeSelections.printMode);
  const pointerStyle = {style: {cursor: 'pointer !important'}}
  return (
    <ResponsiveContainer
      className="test-score-bar"
      width="95%"
      height={data.length * (printMode ? 70 : 85)}
      style={{background: 'red !important'}}
    >
      <BarChart
        layout="vertical"
        data={data}
        barGap={12}
        margin={{
          top: 20,
          right: 0,
          bottom: 20,
          left: 0,
        }}
        onClick={(d: any) => {
          const link = d?.activePayload?.[0]?.payload?.link;
          if (link) {
            window.location.href = `${link}`
          }
        }}
      >
        <XAxis type="number" hide />
        <YAxis
          tick={{
            transform: 'translate(-3, -35)',
            fill: colours.black,
            fontWeight: 'bold',
            fontSize: '16px',
          }}
          axisLine={false}
          dataKey="description"
          type="category"
          width={300}
          tickSize={0}
          mirror
          {...pointerStyle}
        />
        <Bar
          isAnimationActive={false}
          dataKey="value"
          barSize={printMode ? 12 : 15}
          fill={barColours().primary}
          {...pointerStyle}
          //background={{ fill: barColours().background }}
        >
          <LabelList dataKey="value" content={renderCustomizedLabel}/>
        </Bar>
        <Bar
          isAnimationActive={false}
          dataKey="compValue"
          barSize={printMode ? 12 : 15}
          fill={barColours().secondary}
          {...pointerStyle}
          //background={{ fill: barColours().background }}
        >
          <LabelList dataKey="compValue" content={renderCustomizedLabel}/>
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
