import Cookies from 'js-cookie';
import { OemCode, Users, LanguageCode } from 'generated';
import jwt from 'jsonwebtoken';
import { ActiveSelectionsState } from 'sterling-redux/reducers/activeSelections';

export const ENABLE_TESTING = true;

type SortOrder = 'asc' | 'desc';

export interface Obj {
  // eslint-disable-next-line
  [key: string]: any;
}
export type IParams = {
  [key in keyof ActiveSelectionsState]: string | undefined;
};

export type ILabel = Record<LanguageCode, string>;
export type FullTranslation = Record<OemCode, ILabel>;

export const EmptyQueryResult = { loading: false, error: null, data: null };

/** Converts a JWT base64 encoded string string into an object */
/** @param token base64 encoded JWT string */
/** @returns JWT token as an Object */
export const parseJwt = (token: string) => {
  const base64Url: string = token.split('.')[1];
  const base64: string = decodeURIComponent(
    atob(base64Url)
      .split('')
      .map((c: string) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(base64);
};

/** Sorts two objects by a given key in descending order based on the JS output value of < */
/** @param orderBy The name of the key on the object the result will be sorted by */
/** @returns 1, 0 or -1 depending on less than, equal or greater than */
const desc = (a: Obj, b: Obj, orderBy: string): -1 | 0 | 1 => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

/** Uses the desc function to sort an array of objects up or down based on a given key name */
/** @param order Must be either 'asc' or 'desc' */
/** @param orderBy The name of the key on the object the result will be sorted by */
/** @returns A 1, 0 or -1 numerical value that matches the asc or desc argument */
export const getSorting = (order: SortOrder, orderBy: string) => {
  return order === 'desc'
    ? (a: Obj, b: Obj): number => desc(a, b, orderBy)
    : (a: Obj, b: Obj): number => -desc(a, b, orderBy);
};

/** Sorts an array of objects based on given parameters */
/** @param array Array of objects to sort */
/** @param cmp The function you will use to do the comparison */
/** @returns A copy of the array sorted just how you asked */
export const applySort = (array: Obj[], cmp: (...args: Obj[]) => number) => {
  const stabilizedThis = array.map((el: Obj, index: number) => [el, index]);
  stabilizedThis.sort((a: Obj, b: Obj) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: Obj) => el[0]);
};

// IMPORTANT: IF YOU CHANGE THIS YOU NEED TO SYNC THE SAME TYPE ON THE API AS WELL (excluding the DBIdentifier)
declare global {
  type UserToken = Pick<
    Users,
    | '_id'
    | 'username'
    | 'login_type'
    | 'coe_role'
    | 'coe_role_permissions'
    | 'compliance_role'
    | 'preferred_language'
    | 'domains'
    | 'oem_access'
    | 'module_permissions'
    | 'email'
  >;
  // IMPORTANT!!!
}

/** Returns a JSON object value of the 'authentication' cookie */
/** @returns JSON object or undefined if cookie does not exist */
export const getToken = (): UserToken | undefined => {
  const stringCookie: string | undefined = Cookies.get('token');
  let parsedCookie: UserToken | undefined;
  if (stringCookie) {
    parsedCookie = jwt.decode(stringCookie) as UserToken;
  }
  return parsedCookie;
};

export const norm = (val: number, max_val: number): number => {
  return Math.round((val / max_val) * 100);
};

export const percentageString = (val: number, max_val: number): string => {
  return `${((val / max_val) * 100).toFixed(0)}%`;
};

export const percentageDisplay = (normalized_val?: number): string =>
  !normalized_val && normalized_val !== 0 ? '' : `${normalized_val.toFixed(0)}%`;

export const toTitleCase = (phrase: string): string => {
  return phrase
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/(^\S|\s\S)/g, (char) => char.toUpperCase());
};

export const acronymize = (str: string): string =>
  // eslint-disable-next-line
  str.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '');

export type ArrayElement<A> = A extends Array<infer T> ? T : never;

export const randBetween = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min)) + min;

export const normalizeBetween = (num: number, min: number, max: number) =>
  num < min ? min : num > max ? max : num;

export const findMax = <T, K extends keyof T>(anArray: T[], key: K) => {
  return anArray.reduce(
    (acc, curr) => (Number(curr[key]) > Number(acc[key]) ? curr : acc),
    anArray[0]
  );
};

export const findMin = <T, K extends keyof T>(anArray: T[], key: K) => {
  return anArray.reduce(
    (acc, curr) => (Number(curr[key]) < Number(acc[key]) ? curr : acc),
    anArray[0]
  );
};

export const oemNameFromShortCode = (code: OemCode) => {
  switch (code) {
    case 'HACC':
      return 'Hyundai';
    case 'MMSC':
      return 'Mitsubishi Motors Canada';
  }
  return 'Demo';
};

export const oemShortCode = (oemName = 'demo'): OemCode =>
  /(hyundai|hacc|hac)/gi.test(oemName)
    ? 'HACC'
    : /(mitsubishi|mmsc|mmscan)/gi.test(oemName)
      ? 'MMSC'
      : 'DEMO';

export const oemShortCodeFromUrl = (): OemCode => oemShortCode(window.location.href);

export const addSlashes = (url: string) => {
  if (url === '' || url === '/') return '/';
  const urlLeadingSlash = url[0] !== '/' ? `/${url}` : url;
  const urlBothSlashes =
    urlLeadingSlash[urlLeadingSlash.length - 1] !== '/'
      ? `${urlLeadingSlash}/`
      : urlLeadingSlash;
  return urlBothSlashes;
};

export const withFr = (url: string) => {
  const urlBothSlashes = addSlashes(url);
  if (urlBothSlashes.toLowerCase().includes('/fr')) return urlBothSlashes;
  return `/fr${urlBothSlashes}`;
};

export const withoutFr = (url: string) => {
  const urlBothSlashes = addSlashes(url);
  return urlBothSlashes.toLowerCase().includes('/fr')
    ? urlBothSlashes.slice(3)
    : urlBothSlashes;
};

export const getTopLevelPath = (path: string) => {
  // Ignores the /fr/ portion
  const processedPath = withoutFr(addSlashes(path));
  const pathArray = processedPath.split('/');
  if (!(pathArray.length > 1)) return '';
  return addSlashes(pathArray[1]);
};

export const pathMatch = (pathA: string, pathB: string, ignoreFr = false) => {
  if (!pathA || !pathB) return false;
  let processedA = addSlashes(pathA).toLowerCase();
  let processedB = addSlashes(pathB).toLowerCase();

  if (ignoreFr) {
    processedA = withoutFr(processedA);
    processedB = withoutFr(processedB);
  }

  return processedA === processedB;
};

export const appName = { EN: 'Centre of Excellence', FR: 'Centre de votre Excellence' };
export const appShortName = { EN: 'the CoE', FR: 'CdE' };
export const startScreenLineTwo = { EN: 'your Centre of Excellence', FR: `Découvrez ici` };
export const startScreenLineThree = {
  EN: 'experience begins now.',
  FR: `le Centre de votre Excellence.`,
};

export const ENVIRONMENT = {
  Local: 'LOCAL',
  Dev: 'DEV',
  Staging: 'STAGING',
  Production: 'PRODUCTION',
};

export const getEnvironment = () => {
  const { hostname } = window.location;
  if (hostname.indexOf('.localhost.') > -1) return ENVIRONMENT.Local;
  if (hostname.indexOf('.dev.') > -1) return ENVIRONMENT.Dev;
  if (hostname.indexOf('.sandbox.') > -1) return ENVIRONMENT.Dev;
  if (hostname.indexOf('.staging.') > -1) return ENVIRONMENT.Staging;
  return ENVIRONMENT.Production;
};

export const isDevEnvironment = () => ENVIRONMENT.Local === getEnvironment();

export const getUrlLanguage = (url: string): LanguageCode =>
  !addSlashes(url).toLowerCase().startsWith('/fr/') ? 'EN' : 'FR';

export const CurrentOem = oemShortCodeFromUrl();

export interface LangAndOem {
  lang: LanguageCode;
  oem: OemCode;
}

export const handleEnterKey = (e: React.KeyboardEvent, cb: () => void): void => {
  if (e.key === 'Enter') {
    return cb();
  }
};

export const isIE11 = () => 'ActiveXObject' in window;

/***
 * Parses partial datestrings into ISO if browser is IE11
 * and returns Date object
 * Added / replace for Safari support
 */
export const safeDateParse = (date: string): Date => {
  // Relevant formatting info
  // https://stackoverflow.com/a/51715260

  // Replace space between date and time with T
  const dateStringFirstPass = `${date.replace(' ', 'T')}`;

  // Add trailing Z if none exists
  const dateStringSecondPass =
    dateStringFirstPass.slice(-1) === 'Z' ? dateStringFirstPass : `${dateStringFirstPass}Z`;

  return new Date(dateStringSecondPass);
};
