import React from 'react';
import { format, isAfter, add, parseISO, Locale, Duration } from 'date-fns';
import enLocale from 'date-fns/locale/en-CA';
import frLocale from 'date-fns/locale/fr-CA';
import { DateTrackedPost } from 'generated';
import { PostType } from 'components/cms/utility';
import { useLanguage } from 'components/util/Language';
import { safeDateParse } from 'utility';

export function DisplayDate({
  children,
  dateFormat = 'MMMM do, yyyy',
  fallbackText = '',
}: {
  children?: string;
  dateFormat?: string;
  fallbackText?: string;
}) {
  const lang = useLanguage();
  const currentLocale = { EN: enLocale, FR: frLocale }[lang];

  return (
    <>
      {children
        ? format(safeDateParse(children), dateFormat, { locale: currentLocale as unknown as Locale})
        : fallbackText}
    </>
  );
}

const shouldUseUpdatedDate = (post: Partial<DateTrackedPost>) => {
  const { created_date, modified_date } = post;
  return (
    (modified_date &&
      created_date &&
      isAfter(safeDateParse(modified_date), add(safeDateParse(created_date), { days: 1 }))) ||
    (!created_date && modified_date)
  );
};

export function PostedOrUpdatedDate(props: {
  children: Partial<Omit<DateTrackedPost, '__typename'>>;
}) {
  const { children } = props;
  const { created_date, modified_date, post_date } = children;
  const lang = useLanguage();
  const postedLabel = { EN: 'Posted', FR: 'Publié' }[lang];
  const updatedLabel = { EN: 'Updated', FR: 'Mis à jour' }[lang];

  if (post_date)
    return (
      <>
        {postedLabel}: <DisplayDate dateFormat={{EN: 'MMMM do, yyyy', FR: 'dd MMMM yyyy'}[lang]}>{post_date}</DisplayDate>
      </>
    );

  // Use the Updated date label if the updated date is at least 1 day later than the created one
  const useUpdatedDate = shouldUseUpdatedDate({ created_date, modified_date });

  const useCreatedDate = !useUpdatedDate && created_date;

  return useUpdatedDate ? (
    <>
      {updatedLabel}: <DisplayDate>{modified_date}</DisplayDate>
    </>
  ) : useCreatedDate ? (
    <>
      {postedLabel}: <DisplayDate>{created_date}</DisplayDate>
    </>
  ) : (
    <></>
  );
}

interface PostAgeCheckProps {
  children: JSX.Element;
  age: Duration;
  postOrDate: PostType | string;
}

export function ShowIfNewerThan(props: PostAgeCheckProps) {
  const { children, age, postOrDate } = props;
  const isPost = typeof postOrDate !== 'string';
  const post = postOrDate as DateTrackedPost;

  const postDate = isPost
    ? shouldUseUpdatedDate(post)
      ? safeDateParse(post.modified_date)
      : safeDateParse(post.created_date)
    : safeDateParse(postOrDate as string);

  const isOld = isAfter(new Date(), add(postDate, age));

  return isOld ? <></> : <>{children}</>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortbyFieldDate = (a: any, b: any, field: string) =>
  parseISO(b?.[field]).valueOf() > parseISO(a?.[field]).valueOf()
    ? 1
    : parseISO(b?.[field]).valueOf() < parseISO(a?.[field]).valueOf()
    ? -1
    : 0;

export const sortByStartTime = (a:{start_time:string}, b:{start_time:string}) => sortbyFieldDate(a,b,'start_time');

export const sortPostDate = (a: { post_date: string }, b: { post_date: string }) =>
sortbyFieldDate(a,b,'post_date');

