import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as ManualIcon } from '../../assets/icons/manual.svg';
import { ReactComponent as SupportIcon } from '../../assets/icons/support.svg';
import { ReactComponent as UpdatesIcon } from '../../assets/icons/updates.svg';
import { ReactComponent as ManagementIcon } from '../../assets/icons/management.svg';
import { ReactComponent as PerformanceIcon } from '../../assets/icons/my-performance.svg';
import { ReactComponent as EducationCentreIcon } from '../../assets/icons/education-centre.svg';
import { ReactComponent as ResourcesIcon } from '../../assets/icons/resources.svg';
import { ReactComponent as VendorsIcon } from '../../assets/icons/preferred-vendor.svg';
import { ReactComponent as RewardsIcon } from '../../assets/icons/rewards.svg';
import { ReactComponent as EventInviteIcon } from '../../assets/icons/add-calendar.svg';
import { ReactComponent as PlayVideoIcon } from '../../assets/icons/play-triangle.svg';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-pdf.svg';
import { ReactComponent as WebinarsIcon } from '../../assets/icons/webinars.svg';
import { ReactComponent as TimelyProgramUpdates } from '../../assets/icons/welcome-page/timely-program-updates.svg';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const IconMap = {
  standards: <ManualIcon />,
  'program-standards-2021': <ManualIcon />,
  support: <SupportIcon />,
  'latest-updates': <UpdatesIcon />,
  management: <ManagementIcon />,
  performance: <PerformanceIcon />,
  education: <EducationCentreIcon />,
  'preferred-vendors': <VendorsIcon />,
  recognition: <RewardsIcon />,
  rewards: <RewardsIcon />,
  resources: <ResourcesIcon />,
  'add-calendar': <EventInviteIcon />,
  'play-triangle': <PlayVideoIcon />,
  alert: <AlertIcon />,
  download: <DownloadIcon />,
  webinars: <WebinarsIcon />,
  'timely-program-updates': <TimelyProgramUpdates />,
  analytics: <WebinarsIcon />,
  changelog: <FormatListBulletedIcon />,
};

export type IconName = keyof typeof IconMap;

interface DimensionsType {
  w: string;
  h: string;
}

const SvgContainer = styled.span<{ dimensions: DimensionsType }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 0.5;
  ${({ dimensions }: { dimensions: DimensionsType }) => css`
    max-width: ${dimensions.w};
  `}

  > svg {
    cursor: pointer;
    ${({ dimensions }: { dimensions: DimensionsType }) =>
      css`
        height: ${dimensions.h};
        width: ${dimensions.w};
      `}
  }

  // bg fill for download icon
  #prefix__Rectangle_10 {
    fill: transparent !important;
  }
`;

export interface SterlingIconProps {
  title: string;
  name?: IconName;
  dimensions?: DimensionsType;
  // eslint-disable-next-line react/no-unused-prop-types
  style?: React.CSSProperties;
  onClick?: () => void;
}
export function SterlingIcon(props: SterlingIconProps) {
  return <SvgContainer
    onClick={props.onClick}
    title={props.title}
    dimensions={props.dimensions ?? { w: '50px', h: '30px' }}
  >
    {IconMap[props.name as IconName]}
  </SvgContainer>
}
