import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { useSelector } from 'react-redux';
import { AppState } from 'sterling-redux/store';
import { ScoreBarData, barColours } from './ScoreBar';

export function ScoreBarThick(props: { data: ScoreBarData[] }) {
  const { data } = props;

  // compValue for the thick bar stores the index of the rank the user's review falls into
  const myRankIndex: number = data[0].compValue;

  const printMode = useSelector((state: AppState) => state.activeSelections.printMode);

  return (
    <ResponsiveContainer
      className="test-score-bar-thick"
      width="95%"
      height={printMode ? 600 : 700}
    >
      <BarChart
        layout="vertical"
        data={data}
        barGap={12}
        margin={{
          top: 20,
          right: 0,
          bottom: 40,
          left: 0,
        }}
      >
        <XAxis type="number" hide />
        <YAxis
          tick={{
            transform: 'translate(-1, -51)',
            fill: '#000000',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
          axisLine={false}
          dataKey="description"
          type="category"
          width={190}
          tickSize={0}
          mirror
        />
        <Bar
          dataKey="value"
          barSize={printMode ? 60 : 75}
          background={{ fill: barColours().background }}
        >
          {data.map((_entry, index) => (
            <Cell
              key={index}
              fill={index === myRankIndex ? barColours().primary : barColours().secondary}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
