import {
  ApolloClient,
  OperationVariables,
  InMemoryCache,
  ServerError,
  ServerParseError,
  RequestHandler,
  ApolloLink,
} from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { setContext } from '@apollo/client/link/context';
import { oemShortCodeFromUrl, getToken } from 'utility';
import { onError } from '@apollo/client/link/error';

// https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
const cache: InMemoryCache = new InMemoryCache({
  // addTypename: true,
  /*   possibleTypes: {
    Post: ['Webinar', 'ProgramUpdate'],
  }, */
});

// Options are "cache-first" | "network-only" | "cache-only" | "no-cache" | "standby" | "cache-and-network"
const defaultOptions: {
  watchQuery: OperationVariables;
  query: OperationVariables;
  mutate: OperationVariables;
} = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    // [Alex E] This MUST be set to `ignore` in order for you to be able to use .catch in mutations and handle 400 errors.
    // I cannot find any way to let Apollo handle the network errors gracefully by default
    // If you think you can improve how it's handled here's a good discussion of the issue
    // I have tried almost every solution suggested here with no success:
    // https://github.com/apollographql/apollo-link/issues/855
    errorPolicy: 'ignore',
  },
};

const responseMiddleware = onError((e) => {
  // if (networkError) networkError = undefined;
  // if (graphQLErrors) graphQLErrors = [];
  // Any code you need to run AFTER GraphQL requests, add here.
  if (!e.networkError) {
    // store.dispatch(setLoginWarning(''));
    return e.forward(e.operation);
  }

  const typedError = e.networkError as ServerError & ServerParseError;

  if (typedError.statusCode === 403) {
    console.error(`Invalid query: ${e.operation.operationName}`);
  } else if (typedError.statusCode === 400) {
    console.error('Unauthorized');

    if (e.operation.operationName === 'login' && e.graphQLErrors?.length) {
      (e.networkError as ServerError & ServerParseError).statusCode = 429;
    } else if (!getToken()) {
      window.location.reload();
    }
  }

  return e.forward(e.operation);
});

// const requestMiddleware = new ApolloLink((operation, forward) => {
//   // Any code you need to run BEFORE GraphQL requests, add here.
//   return forward!(operation);
// });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // 'Content-Type': 'application/whatever',
      // authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const defaultOem = oemShortCodeFromUrl();

// This custom fetch implementation is used to swap the GraphQL endpoint from Heroku to Digital Ocean.
// The purpose for this switch is to handle larger queries that would otherwise exceed the Heroku memory limit.
// The nginx.conf.erb has a proxy for /services/ that points to REACT_APP_SERVICES_PROXY
const customFetch = (input: RequestInfo | URL, options: RequestInit | undefined) => {
  if (options?.body && typeof process.env.REACT_APP_SERVICES_PROXY !== 'undefined') {
    const { operationName, variables } = JSON.parse(options.body.toString());
    // Export Summary Report (CSV)
    if (operationName === 'newSummaryReport' && variables.pageSize === 1000) {
      return fetch(`${input}`.replace('/api/graphql', '/services/graphql'), options);
    }
  }
  return fetch(`${input}`, options);
};

const httpLink = createUploadLink({
  uri:
    typeof process.env.REACT_APP_API_URL !== 'undefined'
      ? process.env.REACT_APP_API_URL
      : `http://${defaultOem}.localhost.io:4000/graphql`,
  credentials: 'include',
  fetch: customFetch,
});

const apolloClient = new ApolloClient({
  defaultOptions,
  cache,
  link: responseMiddleware
    .concat(authLink)
    .concat(httpLink as unknown as ApolloLink | RequestHandler),
});

export const client = apolloClient;
